import { Promo, PromoListQuery } from "@interfaces/promo"
import PromoServices from "@services/promo.services"
import { useEffect, useState } from "react"

function usePromoList(query?: Partial<PromoListQuery>, initialData: Promo[] = []) {
  const [isLoading, setIsLoading] = useState(false)
  const [promoList, setPromoList] = useState<Promo[]>(initialData)
  const [totalPage, setTotalPage] = useState(0)
  const [nextPage, setNextPage] = useState<number | undefined | null>(0)
  const [currentPage, setCurrentPage] = useState(1)

  const promo = new PromoServices()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await promo.getPromoList(query)

        if (res.isSuccess) {
          const result = res.getValue()
          const data = result.data

          if (result.currentPage === 1) {
            setPromoList(data)
          } else {
            const arr = promoList.concat(data)
            setPromoList(arr)
          }
        } else {
          setPromoList([])
          setTotalPage(0)
          setNextPage(null)
          setCurrentPage(1)
        }

        setIsLoading(false)
      } catch (error: any) {
        setIsLoading(false)
        setPromoList([])
        setTotalPage(0)
        setNextPage(null)
        setCurrentPage(1)
      }
    }

    if (query) {
      getData()
    }
  }, [])

  return {
    isLoading,
    promoList,
    nextPage,
    currentPage,
    totalPage,
  }
}

export default usePromoList
