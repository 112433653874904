import { CustomDot, SliderArrow } from "@components/slider"
import { Course } from "@interfaces/course"
import React from "react"
import Carousel from "react-multi-carousel"
import { ClassCard } from "../old-component/ClassCard"

interface Props {
  screenWidth: number
  courses: Course[]
}

export const NewVideoList = ({ courses, screenWidth }: Props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 586, min: 256 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <Carousel
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      arrows={false}
      className="pb-2"
      itemClass="px-[15px]"
      containerClass="mx-[-15px]"
      {...(screenWidth > 576 && {
        customButtonGroup: (
          <SliderArrow
            length={courses.length}
            displayedSlide={3}
            isNew
            className="justify-end gap-6 mt-7"
          />
        ),
        renderButtonGroupOutside: true,
      })}
      {...(screenWidth < 576 && {
        customDot: <CustomDot />,
        renderDotsOutside: true,
        dotListClass: "relative mt-6 mb-5",
        infinite: true,
        autoPlaySpeed: 3000,
        showDots: true,
      })}
    >
      {courses.map((value, i) => (
        <ClassCard data={value} key={i} />
      ))}
    </Carousel>
  )
}
