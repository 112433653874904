import { CustomDot, SliderArrow } from "@components/slider"
import { Container, LoadingBox } from "@components/wrapper"
import usePromoList from "@lib/hooks/data/usePromoList"
import clsx from "clsx"
import Image from "next/image"
import Carousel from "react-multi-carousel"

export function PromoSlider({ windowWidth }: { windowWidth: number }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 586, min: 256 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const { isLoading, promoList } = usePromoList({ sort: "desc", limit: 100 })

  if (isLoading) {
    return <LoadingBox />
  }

  if (!promoList.length) {
    return <></>
  }

  return (
    <section className={clsx("sm:pt-20 sm:pb-10 py-10 relative bg-[#CADCFF]")}>
      <Container>
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          arrows={false}
          itemClass="px-[15px] rounded-[20px]"
          containerClass="mx-[-15px] rounded-[20px]"
          {...(windowWidth > 576 && {
            customButtonGroup: (
              <SliderArrow
                length={promoList.length}
                displayedSlide={1}
                isNew
                className="justify-end gap-6 mt-9"
              />
            ),
            renderButtonGroupOutside: true,
          })}
          {...(windowWidth < 576 && {
            customDot: <CustomDot isNew/>,
            renderDotsOutside: true,
            dotListClass: "relative mt-6 sm:mb-5",
            // infinite: true,
            // autoPlaySpeed: 3000,
            showDots: promoList.length > 1,
          })}
        >
          {promoList.map((item, i) => (
            <div
              key={i}
              id={`promo-card-${i}`}
              className="cursor-pointer h-full rounded-[20px]"
            >
              <a href={`/promo/${item.discount_slug}`} className="rounded-[20px]">
                <Image
                  width={900}
                  height={550}
                  src={item.discount_image}
                  alt={item.discount_name}
                  className="w-full rounded-[20px] shadow-box"
                />
              </a>
            </div>
          ))}
        </Carousel>
      </Container>
    </section>
  )
}
