import { FlexBox } from "@components/wrapper"
import { flex_center } from "@constants/styles"
import { VideoTestimonies } from "@interfaces/school"
import clsx from "clsx"
import Image from "next/image"
import React from "react"
import { IoPlay } from "react-icons/io5"

interface Props {
  isPlayed: boolean
  testimonial: VideoTestimonies
  onClick: () => void
}

const TestimonyShortPlayer = ({ isPlayed, testimonial, onClick }: Props) => {
  return (
    <div className={clsx("px-4")}>
      {isPlayed && (
        <iframe
          src={`${testimonial.video.video_url.replace("shorts", "embed")}?autoplay=1`}
          className="w-full sm:h-[27vw] h-[155vw] rounded-xl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;web-share; full-screen"
          allowFullScreen
        />
      )}

      {!isPlayed && (
        <div onClick={onClick} className="relative sm:h-[27vw] rounded-xl h-[155vw]">
          <Image
            src={testimonial.video.video_thumbnail}
            width={200}
            height={200}
            alt={testimonial.testimony_username}
            className="wh-full rounded-xl"
          />

          <div
            className={clsx(
              "absolute top-0 left-0  z-[5] transition-all",
              "flex-col flex justify-center",
              "wh-full bg-[rgba(0,0,0,0.3)] rounded-xl"
            )}
          >
            <FlexBox align="center" justify="center" className="sm:p-4 w-fit mx-auto rounded-xl">
              <FlexBox align="center" justify="center" className="w-[74px] h-[74px] rounded-full">
                <button
                  className={clsx("rounded-full bg-[#004CE7] w-[48px] h-[48px]", flex_center)}
                >
                  <IoPlay className="sm:text-3xl text-white" />
                </button>
              </FlexBox>
            </FlexBox>
          </div>

          <div className="absolute bottom-0 left-2 z-50">
            <div className="w-full px-3 relative" />
            <FlexBox
              className={clsx(
                "sm:px-2 sm:pt-2.5 sm:pb-5 text-white",
                "px-4 pb-4 items-center gap-2"
              )}
            >
              <Image
                src={testimonial.testimony_user_picture}
                alt="user picture"
                width={40}
                height={40}
                className="rounded-lg object-cover w-12 h-12"
              />
              <div className="text-sm">
                <p
                  className="font-bold font-urbanist line-clamp-1"
                  title={testimonial.testimony_username}
                >
                  {testimonial.testimony_username}
                </p>
                <p className="line-clamp-1 font-inter" title={testimonial.testimony_user_as}>
                  {testimonial.testimony_user_as}
                </p>
              </div>
            </FlexBox>
          </div>
        </div>
      )}
    </div>
  )
}

export default TestimonyShortPlayer
