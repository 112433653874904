import { Container, FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@constants/const/pictures"
import clsx from "clsx"
import Image from "next/image"
import React from "react"
import { FaCheck, FaCheckCircle } from "react-icons/fa"
import { HomeHeading } from "../old-component/HomeHeading"

const LIST = [
  "Bertumbuh dan meningkatkan skill bersama-sama melalui komunitas yang suportif",
  "Dapatkan berbagai macam informasi dan insights untuk selalu updated pada trend terkini",
  "Dapatkan kesempatan untuk memperluas jejaring dan jenjang karier",
]

export const HomepageCommunity = () => {
  return (
    <section className={clsx("bg-primary-black-1", "sm:py-20 py-10")}>
      <Container className={clsx("grid", "sm:gap-16 gap-9")}>
        <div className="sm:flex justify-between">
          <div className="sm-only:mb-6">
            <HomeHeading headingLevel="h2" className="!text-white sm:text-left text-center">
              Never Stop Growing. <br />
              Harisenin.com Community.
            </HomeHeading>
          </div>

          <div className="sm:w-2/5 grid sm:gap-6 gap-3">
            {LIST.map((item, idx) => (
              <FlexBox key={idx} className="sm:gap-5 gap-3">
                <FlexBox className="bg-[#2A96FF] sm:wh-7 rounded-full wh-5" justify="center" align="center">
                  <FaCheck className="text-white text-sm" />
                </FlexBox>

                <div className="text-white sm:text-2xl sm:w-[90%] w-4/5">{item}</div>
              </FlexBox>
            ))}
          </div>
        </div>

        <div>
          <Image
            src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/homepage_community.webp`}
            width={1216}
            height={423}
            alt="community"
            className="h-auto w-full"
          />
        </div>
      </Container>
    </section>
  )
}
