import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@constants/const/pictures"
import clsx from "clsx"
import Image from "next/image"
import React from "react"

const BENEFITS = [
  {
    title: "Lebih Seru Belajarnya",
    subtitle:
      "Mission-based learning membuat pengalaman belajarmu menyenangkan, nagih, sekaligus menantang seperti bermain game petualangan.",
    backgroundColor: "bg-[#004CE7]",
    isGrey: true,
  },
  {
    title: "Lebih Cepat Pinternya",
    subtitle:
      "Mission-based learning fokus kepada spesialisasi karir yang kamu minati & misi yang akan kamu kerjakan sudah sesuai dengan pekerjaan yang akan kamu kerjakan nantinya.",
    backgroundColor: "bg-[#003BB7]",
  },
  {
    title: "Lebih Cepat Lulus & Kerjanya",
    subtitle:
      "Flexible. Bisa diatur seberapa cepat kamu mau lulus. Semakin cepat kamu menyelesaikan misi semakin cepat kamu lulusnya, cari kerjanya, & tanda tangan offering letternya.",
    backgroundColor: "bg-[#2D69FF]",
    isGrey: true,
  },
  {
    title: "Lebih Aman & Menguntungkan",
    subtitle:
      "Jaminan refund up to 110% kalo kamu nganggur setahun. Investasi paling terjangkau & menguntungkan buat masa depanmu, cukup 1x gajian sudah bikin kamu balik modal 3-7x.",
    backgroundColor: "bg-[#001849]",
  },
]

export const HomepageBenefit = () => {
  return (
    <div className="grid sm:grid-cols-4">
      {BENEFITS.map((ben, idx) => (
        <div key={idx} className="">
          <div
            className={clsx("grid", "sm:p-9 sm:gap-3 px-4.5 py-6", ben.isGrey ? "bg-new-grey" : "bg-body-bg")}
          >
            <div className={clsx("font-urbanist text-primary-black font-bold h-[3em] sm:leading-9", "text-[28px] sm-only:text-center")}>
              {ben.title}
            </div>
            <div>
              <Image
                src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/homepage-asset-0${idx + 1}.webp`}
                width={300}
                height={300}
                alt={ben.title}
                className="w-full"
              />
            </div>
          </div>

          <div className={clsx(ben.backgroundColor, "font-inter text-white", "sm:p-9 sm:h-[13.5em] px-4.5 py-6")}>{ben.subtitle}</div>
        </div>
      ))}
    </div>
  )
}
