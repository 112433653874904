import { LinkButton } from "@components/button"
import { CustomDot, SliderArrow } from "@components/slider"
import { BasicLink } from "@components/typography"
import { container, Container, FlexBox } from "@components/wrapper"
import { Testimonial } from "@interfaces/school"
import MiscServices from "@lib/api/services/misc.services"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import React, { useEffect, useState } from "react"
import Carousel from "react-multi-carousel"
import { HomeHeading } from "../old-component/HomeHeading"

// Slider breakpoint
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}

export const NewTestimonialRow = ({ screenWidth }: { screenWidth: number }) => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([])

  const misc = new MiscServices()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await misc.getTestimonials("school")

        if (res.isSuccess) {
          const data = res.getValue()
          setTestimonials(data)
        }

        // setIsLoading(false)
      } catch (e: any) {
        // school.bugsnagNotifier(e)
        // setIsLoading(false)
      }
    }

    getData()
  }, [])

  return (
    <div className="bg-[#CADCFF] sm:py-20 py-10">
      <Container className="relative sm:mb-9 mb-6">
        <HomeHeading headingLevel="h2" className="mb-3 text-center">
          Apa Kata Alumni Harisenin.com?
        </HomeHeading>
      </Container>

      <div className={clsx(container, "relative sm-only:pb-4")}>
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          arrows={false}
          className="sm:pb-2"
          itemClass="px-[15px]"
          containerClass="mx-[-15px]"
          {...(screenWidth > 576 && {
            customButtonGroup: (
              <SliderArrow
                length={testimonials.length}
                displayedSlide={3}
                isNew
                className="flex justify-center gap-6 mt-7"
              />
            ),
            renderButtonGroupOutside: true,
          })}
          {...(screenWidth < 576 && {
            customDot: <CustomDot isNew />,
            renderDotsOutside: true,
            dotListClass: "relative mt-6 sm:mb-2",
            autoPlaySpeed: 3000,
            showDots: true,
          })}
        >
          {testimonials.map((value) => (
            <div
              key={value.id}
              className={clsx(
                "rounded-lg card-box bg-white sm:px-12 sm:py-6 p-6",
                "flex flex-col justify-between"
              )}
            >
              <div className="grid gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M2 35V26.4754H4.97391C6.33043 26.4754 7.4 26.2842 8.18261 25.9016C9.01739 25.5191 9.61739 24.8087 9.98261 23.7705C10.4 22.6776 10.6087 21.1475 10.6087 19.1803V13.1967L13.1913 18.7705C12.8261 19.0984 12.2783 19.3989 11.5478 19.6721C10.8174 19.8907 10.0087 20 9.12174 20C7.13913 20 5.46956 19.3716 4.11304 18.1148C2.8087 16.8579 2.15652 15.082 2.15652 12.7869C2.15652 10.6557 2.78261 8.82514 4.03478 7.29508C5.33913 5.76503 7.24348 5 9.74783 5C11.3652 5 12.8261 5.40984 14.1304 6.22951C15.487 6.99453 16.5565 8.30601 17.3391 10.1639C18.1739 11.9672 18.5913 14.4262 18.5913 17.541V18.6066C18.5913 22.4317 18.0435 25.5464 16.9478 27.9508C15.8522 30.3552 14.2348 32.1311 12.0957 33.2787C9.95652 34.4262 7.29565 35 4.11304 35H2ZM21.4087 35V26.4754H24.3826C25.7391 26.4754 26.8087 26.2842 27.5913 25.9016C28.4261 25.5191 29.0261 24.8087 29.3913 23.7705C29.8087 22.6776 30.0174 21.1475 30.0174 19.1803V13.1967L32.6 18.7705C32.2348 19.0984 31.687 19.3989 30.9565 19.6721C30.2261 19.8907 29.4174 20 28.5304 20C26.5478 20 24.8783 19.3716 23.5217 18.1148C22.2174 16.8579 21.5652 15.082 21.5652 12.7869C21.5652 10.6557 22.1913 8.82514 23.4435 7.29508C24.7478 5.76503 26.6522 5 29.1565 5C30.7739 5 32.2348 5.40984 33.5391 6.22951C34.8957 6.99453 35.9652 8.30601 36.7478 10.1639C37.5826 11.9672 38 14.4262 38 17.541V18.6066C38 22.4317 37.4522 25.5464 36.3565 27.9508C35.2609 30.3552 33.6435 32.1311 31.5043 33.2787C29.3652 34.4262 26.7043 35 23.5217 35H21.4087Z"
                    fill="#004CE7"
                  />
                </svg>

                <div className="sm:h-[15em] h-[13em] text-primary-black">
                  &quot;{value.testimony}&quot;
                </div>
              </div>

              <div className={clsx("grid gap-4", value.testimony_url_linkedin && '')}>
                <FlexBox className="h-[50px] gap-2" justify="between" align="center">
                  <FlexBox className="gap-4 h-[50px]">
                    <div className="flex flex-1 w-[50px]">
                      <Image
                        src={value.testimony_user_picture}
                        width={140}
                        height={140}
                        alt={value.testimony_username}
                        className="w-[50px] h-[50px] rounded-10 object-cover"
                      />
                    </div>
                    <div className="sm:max-w-[70%] max-w-4/5 text-sm grid gap-1">
                      <div
                        className="font-bold text-primary-black line-clamp-1"
                        title={value.testimony_username}
                      >
                        {value.testimony_username}
                      </div>
                      <div className="text-secondary-grey line-clamp-1">
                        {value.testimony_user_as}
                      </div>
                    </div>
                  </FlexBox>

                  <div className="max-h-[50px]">
                    <Image
                      src={value.company.company_picture}
                      width={140}
                      height={140}
                      alt={value.testimony_username}
                      className="w-auto max-w-[50px]"
                    />
                  </div>
                </FlexBox>

                {value.testimony_url_linkedin ? (
                  <div className="underline-animation">
                    <button
                      onClick={() =>
                        (window.location.href = value.testimony_url_linkedin as string)
                      }
                      className="text-new-blue"
                    >
                      Baca di Linkedin
                    </button>
                  </div>
                ) : (
                  <div className="h-8" />
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
