import { Container } from "@components/wrapper"
import { VideoTestimonies } from "@interfaces/school"
import clsx from "clsx"
import React from "react"
import Carousel, { DotProps } from "react-multi-carousel"
import { BootcampSliderArrow } from "."
import { VideoTestimony } from "@components/video/VideoTestimony"
import { TestimonyShortSlide } from "./TestimonyShortSlide"

const TestimonyVideoRow = ({
  testimonies,
  screenWidth,
}: {
  testimonies: VideoTestimonies[]
  screenWidth: number
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }


  if (!testimonies.length) {
    return <></>
  }

  const youtubeOnly = testimonies.filter((d) => d.video.video_url.includes("youtube"))

  if (!youtubeOnly.length) {
    return <></>
  }

  return (
    <div className="bg-[#101820] sm:py-20 py-10">
      <Container className="relative">
        <h3
          className={clsx(
            "font-urbanist sm:text-[32px] text-white font-semibold mb-3 text-center",
            "text-2xl"
          )}
        >
          Simak Cerita Sukses Alumni Harisenin.com
        </h3>
        <div className="relative sm:gap-15 gap-4.5 sm:flex grid">
          <div className="sm:w-full">
            <TestimonyShortSlide screenWidth={screenWidth} testimonies={youtubeOnly}/>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TestimonyVideoRow
