import { container } from "@components/wrapper"
import clsx from "clsx"
import React from "react"
import { HomeHeading } from "../old-component/HomeHeading"

const PROGRAMS = [
  {
    title: "Corporate Training",
    detail:
      "Perkuat kualitas perusahaan Anda bersama Corporate Training. Dapatkan pelatihan berbasis kurikulum ter-update dengan harga yang terjangkau.",
    link: "/corporate-training",
  },
  {
    title: "Hire Graduates",
    detail:
      "Alumni kami memiliki skill yang relevan dan kompeten di bidangnya. Jadilah Hiring Partner kami dan bangun tim terbaik Anda bersama alumni kami.",
    link: "/hire-graduates",
  },
]

export const OurPrograms = () => {
  return (
    <section className="bg-[#CADCFF]">
      <div className={clsx(container, "sm:py-20 py-6 relative")}>
        <HomeHeading headingLevel="h2" className="sm:mb-15 mb-6 text-center">
          Kembangkan Bisnismu Bersama Kami
        </HomeHeading>

        <div className="sm:gap-10 flex sm-only:flex-col gap-6">
          {PROGRAMS.map((p, i) => (
            <div
              key={i}
              className={clsx(
                "sm:w-1/2 bg-white card-box rounded-lg",
                "sm:py-8 sm:px-12 py-6 px-8"
              )}
            >
              <h6 className="font-urbanist sm:text-xl font-semibold mb-3">{p.title}</h6>
              <p className="sm:mb-5 mb-4">{p.detail}</p>

              <div className="underline-animation">
                <button onClick={() => (window.location.href = p.link)} className="text-new-blue">
                  Lihat Selengkapnya →
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
