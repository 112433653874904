import { CustomDot, SliderArrow } from "@components/slider"
import { FlexBox } from "@components/wrapper"
import { School, SchoolType } from "@interfaces/school"
import clsx from "clsx"
import Image from "next/image"
import React from "react"
import Carousel from "react-multi-carousel"

interface Props {
  screenWidth: number
  data: School[]
  type: SchoolType
}

const ProgramCard = ({ data }: { data: School }) => {
  return (
    <div className="font-inter relative">
      <div className="program-box rounded-[20px]">
        <Image
          src={data.product_asset.product_image}
          alt={data.product_name}
          width={566}
          height={1110}
          className={clsx("object-cover rounded-[20px]", "sm:h-[39vw] h-[155vw]")}
        />
      </div>
      <a
        href={`/school/${data.school_type === "BOOTCAMP" ? "bootcamp" : "proclass"}/${
          data.product_slug
        }`}
      >
        <FlexBox
          className={clsx(
            "absolute z-[10] top-0",
            "px-7.5 pt-7.5 pb-5",
            "bg-linear-bootcamp wh-full rounded-[20px]"
          )}
          direction="col"
          justify="between"
        >
          <div className="grid gap-3">
            <div className={clsx("tracking-widest text-new-blue uppercase", "text-sm font-medium")}>
              {data.school_type === "BOOTCAMP" ? "Bootcamp" : "proclass"}
            </div>
            <div className={clsx("font-urbanist text-black-22 font-bold", "text-2xl")}>{data.product_name}</div>
            <FlexBox className="gap-2">
              {Boolean(data.school_discount_percentage) && (
                <FlexBox
                  className={clsx(
                    "uppercase text-white text-xs font-semibold",
                    "py-[2px] px-2 bg-error rounded-lg"
                  )}
                  align="center"
                >
                  {data.school_discount_percentage}% Off
                </FlexBox>
              )}
              {data.nearest_school_batch.batch_status === "LIMITED_SEAT" && (
                <FlexBox
                  className={clsx(
                    "uppercase text-error text-xs font-semibold",
                    "py-[2px] px-2 rounded-lg border border-error",
                    "bg-[rgba(239,37,71,0.08)]"
                  )}
                  align="center"
                >
                  limited seat
                </FlexBox>
              )}
            </FlexBox>
          </div>

          <div>
            <div className="uppercase text-[#CCFF00]">
              Batch {data.nearest_school_batch.batch_number}
            </div>
            <div className="font-urbanist text-white font-semibold text-lg uppercase">{data.school_schedule}</div>
          </div>
        </FlexBox>
      </a>
    </div>
  )
}

export const NewSchoolList = ({ screenWidth, data, type }: Props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 586, min: 256 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <>
      {/* Web */}
      <section className={clsx("relative")}>
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          arrows={false}
          itemClass="px-[15px] pb-2"
          containerClass="mx-[-15px]"
          {...(screenWidth > 576 && {
            customButtonGroup: (
              <SliderArrow
                length={data.length}
                displayedSlide={4}
                isNew
                className="justify-end gap-6 mt-9"
              />
            ),
            renderButtonGroupOutside: true,
          })}
          {...(screenWidth < 576 && {
            customDot: <CustomDot />,
            renderDotsOutside: true,
            dotListClass: "relative mt-6 mb-5",
            infinite: true,
            autoPlaySpeed: 3000,
            showDots: true,
          })}
        >
          {data.map((value, i) => (
            <ProgramCard data={value} key={i} />
          ))}
        </Carousel>
      </section>

      <section className={clsx("relative", "sm:hidden")}>
        <div className="underline-animation sm:hidden">
          <a
            className="relative"
            href={`/school/${type === "BOOTCAMP" ? "bootcamp" : "proclass"}/`}
          >
            Lihat Selengkapnya →
          </a>
        </div>
      </section>
    </>
  )
}
