import { SliderArrow } from "@components/slider"
import { BasicLink, Heading } from "@components/typography"
import { container } from "@components/wrapper"
import { YoutubeVideo } from "@interfaces/youtube"
import clsx from "clsx"
import Image from "next/image"
import { FC } from "react"
import Carousel, { DotProps } from "react-multi-carousel"
import { HomeHeading } from "../old-component/HomeHeading"

interface YoutubeSliderProps {
  screenWidth: number
  data: YoutubeVideo[]
}

const CustomDot = ({ active, onClick }: DotProps) => {
  return (
    <button
      className={clsx(
        "wh-3 rounded-full mx-1.5 border-0 transition-all duration-200",
        active ? "bg-blue" : "bg-grey-c4 w-auto rounded-0"
      )}
      onClick={(e) => {
        if (onClick) {
          onClick()
        }
        e.preventDefault()
      }}
    />
  )
}

function youtubeThumbnailGetter(item: YoutubeVideo) {
  if (!item) {
    return ""
  }

  if (!item.snippet) {
    return ""
  }

  if (!item.snippet.thumbnails) {
    return ""
  }

  const thumbnails = item.snippet.thumbnails

  if (thumbnails.maxres) {
    return thumbnails.maxres.url
  }

  if (thumbnails.standard) {
    return thumbnails.standard.url
  }

  if (thumbnails.high) {
    return thumbnails.high.url
  }

  return ""
}

export const YoutubeSlider: FC<YoutubeSliderProps> = ({ data, screenWidth }) => {
  const YoutubeVideoBox: FC<{ url: string; imgSource: string; title: string }> = ({
    url,
    imgSource,
    title,
  }) => {
    if (!imgSource) {
      return <></>
    }

    return (
      <BasicLink href={url} isExternal isNewTab>
        <div className="card-box rounded-[20px]">
          <Image
            src={imgSource}
            alt={title}
            className={clsx("w-full object-cover rounded-t-[20px]", "sm:h-[15.6vw] h-[50vw]")}
            width={1280}
            height={720}
          />
          <div className={clsx("p-5")}>
            <div className="uppercase text-blue sm:mb-3 tracking-widest text-sm sm-only:hidden">
              youtube
            </div>
            <Heading
              headingLevel="card-title"
              className={clsx("font-urbanist sm:text-lg sm:h-[2.8em] line-clamp-2 sm:leading-6", "h-[3em]")}
            >
              {title}
            </Heading>
          </div>
        </div>
      </BasicLink>
    )
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  if (!data?.length || !screenWidth) {
    return <></>
  }

  return (
    <section className={clsx("sm:py-10 py-5", container)}>
      <HomeHeading
        headingLevel="h2"
        className={clsx("text-center sm:mb-8", "mb-6 sm-only:w-3/4 mx-auto")}
      >
        Intip Keseruan di Harisenin.com
      </HomeHeading>

      <div className={clsx("relative", "w-full")}>
        {/*// @ts-ignore */}
        <Carousel
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          arrows={false}
          showDots={screenWidth < 576}
          {...(screenWidth > 576 && {
            customButtonGroup: (
              <SliderArrow
                length={data.length}
                displayedSlide={3}
                isNew
                className="flex justify-center gap-6 mt-7"

              />
            ),
            renderButtonGroupOutside: true,
          })}
          sliderClass="pb-2.5"
          {...(screenWidth < 576 && {
            customDot: <CustomDot />,
            renderDotsOutside: true,
            dotListClass: "relative mt-6 mb-2",
            autoPlay: true,
            infinite: true,
            autoPlaySpeed: 3000,
          })}
          containerClass="-mx-[1.15vw] pb-2"
          itemClass="px-[1.15vw]"
        >
          {data.map((item, key) => (
            <YoutubeVideoBox
              url={`https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`}
              imgSource={youtubeThumbnailGetter(item)}
              title={item.snippet.title}
              key={key}
            />
          ))}
        </Carousel>
      </div>
    </section>
  )
}
