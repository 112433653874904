import { CompanySlider } from "@components/harisenin/CompanySlider"
import { container, FlexBox } from "@components/wrapper"
import clsx from "clsx"
import { HomeHeading } from "../old-component/HomeHeading"

export function CompanyRow() {
  return (
    <section className={clsx("lg:py-10 py-5 sm-only:mb-5")}>
      <FlexBox justify="center" align="center" className={clsx("mb-[30px] text-center", container)}>
        <FlexBox direction="col">
          <HomeHeading headingLevel="h2" className="text-center mb-2 mx-auto">
            Perusahaan Top Merekrut Alumni Kami
          </HomeHeading>
        </FlexBox>
      </FlexBox>

      <CompanySlider />
    </section>
  )
}
