import YoutubeRepo from "@api/server-side/youtube.repo"
import { DynamicHelmet } from "@interfaces/misc"
import PublicRepo from "@lib/api/server-side/public.repo"
import LandingPage from "@modules/homepage/LandingPage"

export async function getServerSideProps({ req }: any) {
  const repo = new YoutubeRepo()
  const common = new PublicRepo()

  try {
    const userAgent = req.headers["user-agent"]
    const res = repo.getPlaylistItems(userAgent)
    const res2 = common.getBannerInfo(userAgent)
    const res3 = common.getMetaTag("landing-page", userAgent)
    const res4 = await common.videoTestimonies(userAgent)

    let banner = null
    let meta: DynamicHelmet | null = null
    let youtubes = []
    let videoTestimonies = []

    const [yt, bnr, mt, vt] = await Promise.allSettled([res, res2, res3, res4])

    if (yt.status === "fulfilled" && yt.value) {
      youtubes = (yt.value.body as any).items as any[]
    }

    if (bnr.status === "fulfilled" && bnr.value) {
      banner = JSON.parse(bnr.value.body).result
    }

    if (mt.status === "fulfilled" && mt.value) {
      const result = JSON.parse(mt.value.body).result

      if (result) {
        meta = {
          title: result.meta_title,
          description: result.meta_description,
          tags: result.meta_tags,
        }
      }
    }

    if (vt.status === "fulfilled" && vt.value) {
      videoTestimonies = JSON.parse(vt.value.body).result
    }

    const localBusiness = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "Harisenin.com",
      image:
        "https://www.harisenin.com/cdn-cgi/image/width=128/https://nos.jkt-1.neo.id/harisenin-storage/public/assets/logos/logo_harisenin.svg",
      "@id": "",
      url: "https://www.harisenin.com/",
      telephone: "081312117711",
      priceRange: "10000000",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "13, Conclave Simatupang Jl. Simatupang No.RT 01, RT.13/RW.5, Cilandak Tim., Ps. Minggu",
        addressLocality: "Jakarta Selatan",
        postalCode: "12560",
        addressCountry: "ID",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: -6.2935637,
        longitude: 106.8128532,
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "08:00",
        closes: "17:00",
      },
      sameAs: [
        "https://www.facebook.com/officialharisenincom/",
        "https://twitter.com/harisenincom",
        "https://www.instagram.com/harisenincom/",
        "https://www.youtube.com/c/harisenincom",
        "https://id.linkedin.com/company/harisenin-com",
        "https://www.harisenin.com/",
      ],
      alternateName: "Harisenin Online Course",
      logo: "https://www.harisenin.com/cdn-cgi/image/width=128/https://nos.jkt-1.neo.id/harisenin-storage/public/assets/logos/logo_harisenin.svg",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "081312117711",
        contactType: "customer service",
        areaServed: "ID",
        availableLanguage: "Indonesian",
      },
    }

    const collegeOrUniversity = {
      "@context": "https://schema.org",
      "@type": "CollegeOrUniversity",
      name: "Harisenin.com",
      alternateName: "Harisenin Online Course",
      url: "https://www.harisenin.com/",
      logo: "https://www.harisenin.com/cdn-cgi/image/width=128/https://nos.jkt-1.neo.id/harisenin-storage/public/assets/logos/logo_harisenin.svg",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "081312117711",
        contactType: "customer service",
        areaServed: "ID",
        availableLanguage: "Indonesian",
      },
      sameAs: [
        "https://www.facebook.com/officialharisenincom/",
        "https://twitter.com/harisenincom",
        "https://www.instagram.com/harisenincom/",
        "https://www.youtube.com/c/harisenincom",
        "https://id.linkedin.com/company/harisenin-com",
        "https://www.harisenin.com/",
      ],
    }

    return {
      props: {
        youtubeVideo: youtubes,
        bannerInfo: banner,
        meta,
        localBusiness,
        collegeOrUniversity,
        videoTestimonies,
      },
    }
  } catch (e) {
    console.log(e)

    return {
      props: {
        youtubeVideo: [],
        bannerInfo: null,
      },
    }
  }
}

export default LandingPage
