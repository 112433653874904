import React from "react"
import { GrFacebookOption } from "react-icons/gr"
import { FaLinkedinIn } from "react-icons/fa"
import { AiOutlineInstagram } from "react-icons/ai"
import { FiTwitter } from "react-icons/fi"
import { IconType } from "react-icons"
import clsx from "clsx"

interface SocialMediaLinkProps {
  url: string
  Icon: IconType
}

const SocialMediaLink = ({ Icon, url }: SocialMediaLinkProps) => {
  return (
    <div>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={clsx(
          "sm:wh-8 wh-7.5 border-2 rounded-full p-1 flex justify-center items-center sm-only:text-sm border-grey-ec"
        )}
      >
        <Icon />
      </a>
    </div>
  )
}

function Socmed() {
  const SOCIAL_LINKS: SocialMediaLinkProps[] = [
    {
      Icon: FaLinkedinIn,
      url: "https://www.linkedin.com/company/harisenin-com/",
    },
    {
      Icon: GrFacebookOption,
      url: "https://web.facebook.com/harisenincom",
    },
    {
      Icon: AiOutlineInstagram,
      // url: "https://www.instagram.com/harisenincom/",
      url: "https://www.instagram.com/harisenin.official/",
    },
    {
      Icon: FiTwitter,
      url: "https://twitter.com/harisenincom",
    },
  ]

  return (
    <div className="flex gap-4 w-shrink my-auto">
      {SOCIAL_LINKS.map((sm, i) => (
        <SocialMediaLink {...sm} key={i} />
      ))}
    </div>
  )
}

export default Socmed
