import { Promo, PromoListQuery } from "@interfaces/promo"
import { Result } from "@services/response"
import { PaginationData } from "@interfaces/misc"
import PromoRepositories from "../repositories/promo.repositories"

export default class PromoServices extends PromoRepositories {
  async getPromoList(query?: Partial<PromoListQuery>) {
    try {
      const res = await this.getRawPromoList(query)

      const response = await this._getResponse<PaginationData<Promo>>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<PaginationData<Promo>>()
      }

      return Result.ok<PaginationData<Promo>>(result)
    } catch (e: any) {
      return Result.fail<PaginationData<Promo>>()
    }
  }
}
