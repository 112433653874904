import { Avatar, ImageCover, StarRating } from "@components/misc"
import { Heading } from "@components/typography"
import { FlexBox } from "@components/wrapper"
import { HARISENIN_LOGO } from "@constants/const/pictures"
import { Course } from "@interfaces/course"
import { priceFormatter } from "@lib/functions"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"

interface ClassCardProps {
  data: Course
  className?: string
}

export const ClassCard: React.FC<ClassCardProps> = ({ data, className }) => {
  const CardContent = () => (
    <div className="rounded-[20px] card-box">
      <div className="relative">
        <Image
          src={data.course_thumbnail?.course_thumbnail_path ?? ""}
          alt="class"
          width={990}
          height={540}
          className={clsx("w-full sm:h-[15.5vw] rounded-t-[20px]", "h-[48vw]")}
        />

        <div className="flex flex-end">
          <div
            className={clsx(
              "absolute top-0 right-0 object-contain bg-white p-1 border border-[rgba(58,53,65,0.12)]",
              "py-1.5 px-3 rounded-bl rounded-tr-[20px]"
            )}
          >
            <Image
              src={data.partnership?.partnership_logo ?? HARISENIN_LOGO}
              alt={data.partnership?.partnership_name}
              width={400}
              height={200}
              className="rounded-bl rounded-tr-[20px] h-3.5 w-auto"
            />
          </div>
        </div>

        {/* {data.course_label === "PRAKERJA" && (
          <div className="absolute top-2.5 right-2.5 bg-[rgba(248,248,248,0.9)] rounded py-0.5 px-3 sm:text-sm text-xs font-semibold">
            Prakerja
          </div>
        )} */}
      </div>

      <div className={clsx("p-5 hover:text-black sm:text-black", "grid sm:gap-3 gap-2")}>
        <div className="uppercase text-new-blue tracking-widest text-sm">
          Video course
        </div>

        <Heading
          headingLevel="card-title"
          className={clsx("!text-lg h-[3.5em] line-clamp-2 sm:leading-6")}
          title={data.course_name}
        >
          {data.course_name}
        </Heading>

        {data.course_status === "ACTIVE" ? (
          <>
            <FlexBox className="gap-3 ">
              <Avatar
                src={data.course_teacher.teacher_picture}
                alt={data.course_teacher.teacher_name}
                size={40}
                className="sm:wh-10 wh-8 sm-only:mt-1"
              />
              <div>
                <div className={clsx("font-semibold text-sm")}>
                  {data.course_teacher.teacher_name}
                </div>
                <div className="sm:text-sm text-xs line-clamp-1 break-all">
                  {data.course_teacher.teacher_last_job}
                </div>
              </div>
            </FlexBox>

            <FlexBox align="center">
              {data.course_discount_percentage > 0 ? (
                <>
                  <div
                    className={clsx(
                      "text-white bg-red font-semibold sm:text-sm rounded-[2px] leading-[20px] mr-2.5 sm:px-2 ",
                      "text-xs px-1.5"
                    )}
                  >
                    {data.course_discount_percentage}%
                  </div>
                  <div className={clsx("text-new-blue sm:text-lg font-semibold", "text-base")}>
                    {priceFormatter(data.course_price)}
                  </div>
                  <div className={clsx("line-through text-grey-97 ml-2 sm:text-sm", "text-xs")}>
                    {priceFormatter(data.course_original_price)}
                  </div>
                </>
              ) : (
                <div className={clsx("text-new-blue sm:text-lg sm:font-semibold", "font-medium")}>
                  {priceFormatter(data.course_price)}
                </div>
              )}
            </FlexBox>
          </>
        ) : (
          <FlexBox align="center" justify="center" className="h-[100px]">
            <div className="text-primary-grey text-sm text-center font-semibold bg-new-blue rounded w-3/4 py-2">
              Segera Hadir!
            </div>
          </FlexBox>
        )}
      </div>
    </div>
  )
  return (
    <>
      {data.course_status === "ACTIVE" ? (
        <Link href={`/learning/kelas/${data.course_slug}`} className={clsx(className)}>
          <CardContent />
        </Link>
      ) : (
        <div className={clsx(className, "cursor-not-allowed")}>
          <CardContent />
        </div>
      )}
    </>
  )
}
