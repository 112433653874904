import { LinkButton } from "@components/button"
import { FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@const/pictures"
import clsx from "clsx"
import Image from "next/image"

export function CtaWebapp() {
  return (
    <section
      className={clsx(
        "bg-[#0f1820] relative w-full sm:py-9 py-[72px]",
        "flex items-center justify-center"
      )}
    >
      {/* <Image
        src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/home_cta-assets-1.png`}
        alt="cta-top-left"
        width={300}
        height={300}
        className="absolute top-0 left-0 w-[100px] sm-only:hidden"
      />
      <Image
        src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/home_cta-assets-2.png`}
        alt="cta-top-right"
        width={300}
        height={300}
        className="absolute top-0 right-0 w-[100px] sm-only:hidden"
      /> */}

      <FlexBox
        className="m-auto text-white sm:h-[300px] sm:gap-8 sm-only:px-[18px]"
        justify="center"
        align="center"
        direction="col"
      >
        <div className="grid gap-3 sm-only:mb-6">
          <h3
            className={clsx(
              "font-urbanist sm:text-[40px] text-white font-semibold text-center",
              "text-2xl sm-only:mb-3"
            )}
          >
            Galau? Bingung?
          </h3>
          <div className="sm:text-2xl text-center">
            Obrolin dulu aja dengan team admission harisenin.com!
          </div>
        </div>
        <LinkButton
          href="https://api.whatsapp.com/send?phone=6281312117711"
          className={clsx("!font-inter w-fit !rounded-10")}
          customPadding="sm:py-[14px] sm:px-[50px] py-4 px-6 sm-only:w-full"
          variant="new-blue"
        >
          Klik untuk Konsultasi. Gratis!
        </LinkButton>
      </FlexBox>

      {/* <Image
        src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/home_cta-assets-3.png`}
        alt="cta-bottom-right"
        width={300}
        height={300}
        className="absolute bottom-0 right-0 w-[50px] h-auto sm-only:hidden"
      />
      <Image
        src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/home_cta-assets-4.png`}
        alt="cta-bottom-left"
        width={300}
        height={300}
        className="absolute bottom-0 left-0 w-[100px] sm-only:hidden"
      /> */}
    </section>
  )
}
