import { NextArrow, PrevArrow } from "@components/slider"
import clsx from "clsx"
import { ButtonGroupProps } from "react-multi-carousel"

interface BootcampSliderArrowProps extends ButtonGroupProps {
  displayedSlide: number
  length: number
  className?: string
}

export const BootcampSliderArrow: React.FC<BootcampSliderArrowProps> = ({
  next,
  previous,
  displayedSlide,
  length,
  className,
  ...props
}) => {
  const current = props.carouselState?.currentSlide

  if (length <= displayedSlide) {
    return <></>
  }

  return (
    <div className={clsx(`z-10 flex w-full`, className)}>
      <PrevArrow
        disabled={current === 0 || length < displayedSlide}
        className={clsx(
          // size === "lg" ? "w-[50px] h-[50px]" : 'w-[40px] h-[40px]',
          "flex items-center justify-center bg-white rounded-full",
          "w-[44px] h-[44px]"
        )}
        onClick={previous}
      />
      <NextArrow
        onClick={next}
        disabled={length - displayedSlide === current || length < displayedSlide}
        className={clsx(
          "flex items-center justify-center bg-white rounded-full",
          "w-[44px] h-[44px]"
        )}
      />
    </div>
  )
}
