import React from "react"
import Carousel from "react-multi-carousel"
import { Risebinar } from "@interfaces/risebinar"
import { CustomDot, SliderArrow } from "@components/slider"
import { ImageCover } from "@components/misc"
import clsx from "clsx"
import { FlexBox } from "@components/wrapper"
import { FaRegCalendar, FaRegClock } from "react-icons/fa"
import { dateFormatter, priceFormatter } from "@lib/functions"
import dayjs from "dayjs"

interface Props {
  screenWidth: number
  data: Risebinar[]
}

export const NewWorkshopList = ({ screenWidth, data }: Props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 586, min: 256 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  console.log(data)

  return (
    <div>
      <Carousel
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        arrows={false}
        className="pb-2"
        itemClass="px-[15px]"
        containerClass="mx-[-15px]"
        {...(screenWidth > 576 && {
          customButtonGroup: (
            <SliderArrow
              length={data.length}
              displayedSlide={3}
              isNew
              className="justify-end gap-6 mt-7"
            />
          ),
          renderButtonGroupOutside: true,
        })}
        {...(screenWidth < 576 && {
          customDot: <CustomDot />,
          renderDotsOutside: true,
          dotListClass: "relative mt-6 mb-5",
          infinite: true,
          autoPlaySpeed: 3000,
          showDots: true,
        })}
      >
        {data.map((value, index) => (
          <a
            href={`/risebinar/${value.risebinar_slug}`}
            className="hover:text-black-48 card-box"
            id={`event-card-${index}`}
            key={index}
          >
            {/*{data.is_accept_order ? null : (*/}
            {/*  <div className="sold-out_label">SOLD OUT</div>*/}
            {/*)}*/}
            <div className="relative rounded-[20px] card-box">
              <ImageCover
                size={[990, 550]}
                src={value.risebinar_asset?.asset_path ?? ""}
                alt={value.risebinar_slug}
                className={clsx("w-full sm:h-[15.5vw] rounded-t-[20px]", "h-[48vw]")}
              />

              <div
                className={clsx(
                  "p-5 hover:text-black bg-white rounded-b-[20px]",
                  "grid sm:gap-3 gap-2"
                )}
              >
                <div
                  id={`event-title-${index}`}
                  className="uppercase text-new-blue tracking-widest text-sm"
                >
                  {value.risebinar_category?.category_name}
                </div>

                <h5
                  title={value.risebinar_title?.length > 75 ? value.risebinar_title : ""}
                  className={clsx(
                    "font-semibold h-[3em] line-clamp-2 sm:leading-normal text-lg",
                    "h-[2em] leading-1"
                  )}
                >
                  {value.risebinar_title}
                </h5>

                <div className="grid gap-2">
                  <FlexBox align="center">
                    <div className="uppercase sm:text-sm text-xs text-[#b3b3b3] sm:w-[70px] w-[60px]">
                      Tanggal
                    </div>

                    {/* <div className="w-6 sm:hidden">
                      <FaRegCalendar />
                    </div> */}
                    <div className="sm:text-sm text-xs sm:font-semibold font-medium">
                      {dateFormatter(value.risebinar_program.program_start, "D MMMM YYYY")} -{" "}
                      {dateFormatter(value.risebinar_program.program_end, "D MMMM YYYY")}
                    </div>
                  </FlexBox>

                  <FlexBox align="center">
                    <div className="uppercase sm:text-sm text-xs text-[#b3b3b3] sm:w-[70px] w-[60px]">
                      Waktu
                    </div>
                    {/* <div className="w-6 sm:hidden">
                      <FaRegClock />
                    </div> */}
                    <div className="sm:text-sm text-xs sm:font-semibold font-medium">
                      {value.risebinar_time}
                    </div>
                  </FlexBox>
                </div>

                <div className={clsx("text-new-blue sm:text-lg font-medium")}>
                  {priceFormatter(value.risebinar_program?.program_price)}
                </div>
              </div>
            </div>
          </a>
        ))}
      </Carousel>
    </div>
  )
}
