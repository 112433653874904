import Image from "next/image"
import React from "react"

const ImageComponent = ({ value }: { value: number }) => {
  return (
    <Image
      width={390}
      height={210}
      src={`/company/390x210-${value}.png`}
      alt="company-logo"
      className="mx-4 sm:h-[70px] h-9 w-auto"
    />
  )
}

export const CompanySlider = () => {
  const IMAGE_ROW_STYLE_LEFT = "flex w-max animate-left"
  const IMAGE_ROW_STYLE_RIGHT = "flex w-max animate-right"

  return (
    <>
      <div className="overflow-hidden">
        <div className={IMAGE_ROW_STYLE_LEFT}>
          <div className="flex w-max">
            {Array.from({ length: 12 }, (_, i) => i + 1).map((value, index) => (
              <ImageComponent key={index} value={value} />
            ))}
          </div>
          <div className="flex w-max">
            {Array.from({ length: 12 }, (_, i) => i + 1).map((value, index) => (
              <ImageComponent key={index} value={value} />
            ))}
          </div>
        </div>
      </div>

      <div className="overflow-hidden sm:mt-8 mt-8">
        <div className={IMAGE_ROW_STYLE_RIGHT}>
          <div className="flex m-0">
            {Array.from({ length: 12 }, (_, i) => i + 13).map((value, index) => (
              <ImageComponent key={index} value={value} />
            ))}
          </div>
          <div className="flex m-0">
            {Array.from({ length: 12 }, (_, i) => i + 13).map((value, index) => (
              <ImageComponent key={index} value={value} />
            ))}
          </div>
        </div>
      </div>

      <div className="overflow-hidden sm:mt-8 mt-8">
        <div className={IMAGE_ROW_STYLE_LEFT}>
          <div className="flex m-0">
            {Array.from({ length: 12 }, (_, i) => i + 25).map((value, index) => (
              <ImageComponent key={index} value={value} />
            ))}
          </div>
          <div className="flex m-0">
            {Array.from({ length: 12 }, (_, i) => i + 25).map((value, index) => (
              <ImageComponent key={index} value={value} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
