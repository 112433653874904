import clsx from "clsx"
import { DotProps } from "react-multi-carousel"

interface Props extends DotProps {
  isNew?: boolean
}

export const CustomDot = ({ active, onClick, isNew }: Props) => {
  return (
    <button
      className={clsx(
        "wh-3 rounded-full mx-1.5 border-0 transition-all duration-200",
        active ? "bg-blue" : isNew ? "bg-white" : "bg-[#D9D9D9]"
      )}
      onClick={(e) => {
        if (onClick) {
          onClick()
        }
        e.preventDefault()
      }}
    />
  )
}
