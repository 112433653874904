import React, { FC } from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { ButtonGroupProps } from "react-multi-carousel"
import clsx from "clsx"

interface ArrowProps {
  className?: string
  onClick?: () => void
  iconSize?: string
  disabled?: boolean
}

interface ArrowRowProp extends ButtonGroupProps {
  length: number
  size?: "md" | "lg"
  displayedSlide: number
  className?: string
  leftClassName?: string
  rightClassName?: string
  iconSize?: string
  isNew?: boolean
}

export function PrevArrow({ onClick, className, iconSize, disabled }: ArrowProps) {
  return (
    <button
      onClick={onClick}
      className={clsx("left-[-10px] card-box", className, !disabled && "border-blue border")}
      disabled={disabled}
    >
      <FaChevronLeft
        className={clsx(
          "text-[18px]",
          iconSize ?? "text-[18px]",
          disabled ? "text-[#959298]" : "text-new-blue"
        )}
      />
    </button>
  )
}

export function NextArrow({ onClick, className, iconSize, disabled }: ArrowProps) {
  return (
    <button
      onClick={onClick}
      className={clsx("right-[-10px] card-box", className, !disabled && "border-blue border")}
      disabled={disabled}
    >
      <FaChevronRight
        className={clsx(
          "text-[18px] ",
          iconSize ?? "text-[18px]",
          disabled ? "text-[#959298]" : "text-new-blue"
        )}
      />{" "}
    </button>
  )
}

export const SliderArrow: FC<ArrowRowProp> = ({
  next,
  previous,
  length,
  displayedSlide,
  size,
  className,
  leftClassName,
  rightClassName,
  iconSize,
  isNew,
  ...props
}) => {
  const current = props.carouselState?.currentSlide


  if(length <= displayedSlide) {
    return <></>
  }

  return (
    <div className={clsx(`z-10 flex w-full`, className)}>
      <PrevArrow
        disabled={current === 0 || length < displayedSlide}
        className={clsx(
          // size === "lg" ? "w-[50px] h-[50px]" : 'w-[40px] h-[40px]',
          "flex items-center justify-center bg-white rounded-full",
          isNew
            ? "w-[44px] h-[44px]"
            : "absolute top-[42%] shadow-[0_2px_5px_-0px_rgba(0,0,0,0.3)] w-[50px] h-[50px]",
          leftClassName
        )}
        iconSize={iconSize}
        onClick={previous}
      />
      <NextArrow
        onClick={next}
        iconSize={iconSize}
        disabled={length - displayedSlide === current || length < displayedSlide}
        className={clsx(
          "flex items-center justify-center bg-white rounded-full",
          isNew
            ? "w-[44px] h-[44px]"
            : "absolute top-[42%] shadow-[0_2px_5px_-0px_rgba(0,0,0,0.3)] w-[50px] h-[50px]",
          rightClassName
        )}
      />
    </div>
  )
}
