import { CustomDot } from "@components/slider"
import { Container } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@constants/const/pictures"
import { createMarkup } from "@lib/functions"
import clsx from "clsx"
import Image from "next/image"
import React, { useState } from "react"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
import Carousel from "react-multi-carousel"
import { HomeHeading } from "../old-component/HomeHeading"

interface Props {
  id: number
  asset: string
  copyText: string
}

const LIST: Props[] = [
  {
    id: 1,
    asset: "homepage_value-1.webp",
    copyText:
      "Mission-based learning memberikan pengalaman belajar seperti game petualangan yang ga ngebosenin & nagih, di mana kamu harus menyelesaikan misi di setiap levelnya (basic s.d. advance) untuk bisa menyelesaikan program bootcamp.<br /> <br />Jadi kamu bisa atur seberapa cepat kamu mau lulus, semakin cepat kamu menyelesaikan misimu maka akan semakin cepat juga kamu lulus.",
  },
  {
    id: 2,
    asset: "homepage_value-2.webp",
    copyText:
      "Tidak hanya pengalaman belajar secara online, kamu juga akan memiliki misi yang sifatnya physical (offline) di mana kamu perlu untuk berinteraksi dan juga melakukan research secara langsung.",
  },
  {
    id: 3,
    asset: "homepage_value-3.webp",
    copyText:
      "Bukan cuma berpengalaman dibidangnya, mentor-mentor harisenin.com telah dipilih yang memang terpanggil dan peduli untuk berkontribusi terhadap perkembangan skill generasi muda Indonesia.",
  },
  {
    id: 4,
    asset: "homepage_value-4.webp",
    copyText:
      "Kamu akan mendapatkan bantuan karir secara personal. Kami percaya setiap manusia memiliki potensi & juga keadaan yang berbeda-beda. <br /> <br /> Di mana kami akan memberikan strategi khusus agar kamu dapat mendapatkan pekerjaan yang kamu mau & impikan dengan lebih cepat dan mudah.",
  },
  {
    id: 5,
    asset: "homepage_value-5.webp",
    copyText:
      "Berbeda dari yang lain, di mana kamu diharuskan untuk menjalani semua sesi dan materi yang mungkin kamu kurang tertarik. Di mission-based learning harisenin.com kamu akan belajar lebih dalam dan lebih fokus sesuai dengan spesialisasi yang kamu mau. Sehingga kamu bisa menjadi lebih ahli tanpa harus membuang banyak waktu. ",
  },
  {
    id: 6,
    asset: "homepage_value-6.webp",
    copyText:
      "Investasi yang terjangkau & masuk akal, cukup gajian 1 bulan aja, investasimu sudah bisa balik modal 2x-5x. Gak harus nunggu 3-12 bulan gajian baru balik modal 1x <br /> <br /> Plus investasimu di harisenin lebih aman karena ada jaminan uang kembali up to 110% (tertinggi di industri).",
  },
  {
    id: 7,
    asset: "homepage_value-7.webp",
    copyText:
      "Gak hanya isi kepala, harisenin.com juga ingin membuat alumni kami lebih siap secara mental ketika nanti terjun di dunia kerja. <br /> <br /> Jadi persoalan kesehatan mental health ini menjadi perhatian lebih dari harisenin.com.",
  },
]

const ValueBox = ({
  value,
  className,
  isActive,
  onClick,
}: {
  value: Props
  className: string
  isActive: boolean
  onClick: (index: number) => () => void
}) => {
  return (
    <div className={clsx(className, "relative")}>
      <Image
        src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/${value.asset}`}
        width={500}
        height={500}
        className="wh-full object-cover rounded-[20px]"
        alt={`Value-${value.id}`}
      />
      {isActive && (
        <div
          className={clsx(
            "absolute top-0 left-0",
            "wh-full rounded-[20px] overlay-value",
            "p-8"
          )}
        >
          <div
            className="text-white whitespace-pre-line w-full"
            dangerouslySetInnerHTML={createMarkup(value.copyText)}
          />
        </div>
      )}
      <button
        className={clsx(
          "absolute bottom-6 right-6",
          "py-2 wh-9",
          "text-new-blue border border-new-blue rounded-full bg-white",
          "flex items-center justify-center"
        )}
        onClick={onClick(value.id)}
      >
        {!isActive ? <AiOutlinePlus /> : <AiOutlineMinus />}
      </button>{" "}
    </div>
  )
}

export const HomepageValue = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 586, min: 256 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const [openedIndex, setOpenedIndex] = useState(0)

  const clickIndex = (index: number) => () => {
    if (index === openedIndex) {
      setOpenedIndex(0)
    } else {
      setOpenedIndex(index)
    }
  }

  return (
    <section className={clsx("bg-[#101820]", "sm:pt-20 sm:pb-[120px] pt-10 pb-10")}>
      <Container>
        <HomeHeading
          headingLevel="h2"
          className={clsx("!text-white !text-center w-full", "sm:mb-15 mb-6")}
        >
          Gapai Mimpi. <br className="sm:hidden" /> Lebih Cepat. <br className="sm:hidden" /> Lebih
          Menyenangkan.
        </HomeHeading>

        <div className={clsx("grid sm:gap-3", "sm:grid-cols-3 sm:grid-rows-3", "sm-only:hidden")}>
          <ValueBox
            className="row-span-2"
            value={LIST[0]}
            isActive={openedIndex === 1}
            onClick={clickIndex}
          />
          {[1, 2, 3, 4].map((i, j) => (
            <ValueBox
              className=""
              value={LIST[i]}
              key={j}
              isActive={openedIndex === i + 1}
              onClick={clickIndex}
            />
          ))}
          <ValueBox
            className=""
            value={LIST[5]}
            isActive={openedIndex === 6}
            onClick={clickIndex}
          />
          <ValueBox
            className="col-span-2"
            value={LIST[6]}
            isActive={openedIndex === 7}
            onClick={clickIndex}
          />
        </div>

        <div className="relative sm:hidden">
          <Carousel
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            arrows={false}
            {...{
              customDot: <CustomDot />,
              renderDotsOutside: true,
              dotListClass: "relative mt-6",
              infinite: true,
              autoPlaySpeed: 3000,
              showDots: true,
            }}
            itemClass="px-[15px]"
            containerClass="mx-[-15px]"
          >
            <ValueBox
              className="h-full"
              value={LIST[0]}
              isActive={openedIndex === 1}
              onClick={clickIndex}
            />
            <div className="grid gap-5">
              {[1, 2].map((i, j) => (
                <ValueBox
                  className=""
                  value={LIST[i]}
                  key={j}
                  isActive={openedIndex === i + 1}
                  onClick={clickIndex}
                />
              ))}
            </div>
            <div className="grid gap-5">
              {[3, 4].map((i, j) => (
                <ValueBox
                  className=""
                  value={LIST[i]}
                  key={j}
                  isActive={openedIndex === i + 1}
                  onClick={clickIndex}
                />
              ))}
            </div>
            <div className="grid gap-5">
              {[5, 6].map((i, j) => (
                <ValueBox
                  className="h-[91vw]"
                  value={LIST[i]}
                  key={j}
                  isActive={openedIndex === i + 1}
                  onClick={clickIndex}
                />
              ))}
            </div>
          </Carousel>
        </div>
      </Container>
    </section>
  )
}
