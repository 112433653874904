import { FlexBox } from "@components/wrapper"
import { Alumnus, PaginationData } from "@interfaces/misc"
import MiscServices from "@lib/api/services/misc.services"
import clsx from "clsx"
import Image from "next/image"
import { useEffect, useState } from "react"

const AlumnusCard = ({ data }: { data: Alumnus }) => {
  return (
    <div
      className={clsx(
        "sm:w-[13.33vw] w-[50vw]",
        "border border-new-grey rounded-10 shadow-[0px_2px_10px_0px_rgba(58,53,65,0.10)]"
      )}
    >
      <div>
        <Image
          src={data.alumni_user_picture}
          width={200}
          height={200}
          alt={data.alumni_username}
          className="sm:w-[13.33vw] sm:h-[13.33vw] h-[50vw] w-[50vw] rounded-t-10"
        />
      </div>
      <div
        className={clsx(
          "bg-white relative rounded-b-10",
          "px-4 sm:pb-4 sm:pt-[22px]",
          "pt-2.5 pb-2.5"
        )}
      >
        <FlexBox
          justify="center"
          align="center"
          className={clsx(
            "bg-body-bg border rounded border-new-grey p-2",
            "absolute sm:-top-7 -top-10 right-4",
            "wh-12"
          )}
        >
          <Image
            src={data.alumni_company_picture}
            width={48}
            height={48}
            alt={data.alumni_company_name}
            className="wh-auto"
          />
        </FlexBox>
        <div className="grid gap-2">
          <div
            className={clsx(
              "font-urbanist text-[#222]",
              "sm:text-sm text-xs font-bold sm:line-clamp-1 line-clamp-2 sm-only:h-[2.5em]"
            )}
          >
            {data.alumni_username}
          </div>
          <div className="font-inter sm:text-xs text-[10px] text-secondary-grey line-clamp-1">
            {data.alumni_user_as}
          </div>
        </div>
      </div>
    </div>
  )
}

export const HomepageAlumnusRow = () => {
  const [schoolAlumnus, setSchoolAlumnus] = useState<Alumnus[]>([])

  const misc = new MiscServices()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await misc.getAlumnus({ limit: 10 })

        if (res.isFailure) {
          return
        } else {
          const result = res.getValue()
          const data = result.data

          if (data.length > 10) {
            const spliced = data.slice(0, 10)
            setSchoolAlumnus(spliced)
          } else {
            setSchoolAlumnus(data)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    getData()
  }, [])

  if (!schoolAlumnus.length) {
    return <></>
  }

  return (
    <div className={clsx("bg-[#0a0a0a]", "sm:pt-7.5 sm:pb-15 pt-5 pb-5")}>
      <div className="overflow-hidden">
        <div className="flex w-max animate-left sm:gap-9 gap-4.5">
          <div className="flex w-max sm:gap-9 gap-4">
            {schoolAlumnus.map((alumnus) => (
              <AlumnusCard key={alumnus.id} data={alumnus} />
            ))}
          </div>
          <div className="flex w-max sm:gap-9 gap-4.5">
            {schoolAlumnus.map((alumnus) => (
              <AlumnusCard key={alumnus.id} data={alumnus} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
