import clsx from "clsx"
import React from "react"

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  headingLevel: "h2" | "h3"
}

export const HomeHeading = ({ headingLevel, className, children, ...props }: HeadingProps) => {
  switch (headingLevel) {
    case "h2":
      return (
        <h2
          className={clsx(
            "font-urbanist font-semibold sm:text-[40px] sm:leading-[52px]",
            "text-2xl leading-[32px]",
            className
          )}
          {...props}
        >
          {children}
        </h2>
      )
    case "h3":
      return (
        <h3 className={clsx("sm:text-2xl sm:leading-8", "leading-5", className)} {...props}>
          {children}
        </h3>
      )
    default:
      return (
        <div className={className} {...props}>
          {children}
        </div>
      )
  }
}
