import { CustomDot } from "@components/slider"
import TestimonyShortPlayer from "@components/video/TestimonyShortPlayer"
import { VideoTestimonies } from "@interfaces/school"
import clsx from "clsx"
import React, { useState } from "react"
import Carousel from "react-multi-carousel"
import { BootcampSliderArrow } from "."

export const TestimonyShortSlide = ({
  testimonies,
  screenWidth,
}: {
  testimonies: VideoTestimonies[]
  screenWidth: number
}) => {
  const [indexPlayed, setIndexPlayed] = useState(-1)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const videoToggler = (index: number) => () => {
    console.log('clicked')
    setIndexPlayed(index)
  }

  return (
    <div className="sm-only:w-[90vw]">
      <Carousel
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        containerClass={clsx("sm:-ml-4 sm:py-5 px-0 gap-5 sm-only:-mx-2.5")}
        itemClass="sm-only:mb-5"
        arrows={false}
        showDots={screenWidth < 576 && testimonies.length > 1}
        {...(screenWidth > 576 && {
          customButtonGroup: (
            <BootcampSliderArrow
              displayedSlide={5}
              length={testimonies.length}
              className="gap-6 justify-center"
            />
          ),
          renderButtonGroupOutside: true,
        })}
        {...(screenWidth < 576 && {
          customDot: <CustomDot />,
          renderDotsOutside: true,
          dotListClass: "!-bottom-3",
          // autoPlay: true,
          // infinite: true,
          autoPlaySpeed: 3000,
        })}
      >
        {testimonies.map((value, i) => (
          <TestimonyShortPlayer
            isPlayed={i === indexPlayed}
            testimonial={value}
            key={i}
            onClick={videoToggler(i)}
          />
        ))}
      </Carousel>
    </div>
  )
}
