import { LinkButton } from "@components/button"
import { FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_PAGE_ASSETS } from "@constants/const/pictures"
import { IMAGE_COVER } from "@constants/styles"
import { Transition } from "@headlessui/react"
import { BannerInfo } from "@interfaces/misc"
import useInterval from "@lib/hooks/useInterval"
import clsx from "clsx"
import Image from "next/image"
import { FC, Fragment, useState } from "react"
import { BsDot } from "react-icons/bs"

export const HomeBanner: FC<{ info: BannerInfo | null }> = ({ info }) => {
  const WORD_LIST = [
    "Gak Punya Privilege",
    "Beda Jurusan",
    "Pindah Jalur Karir",
    "Baru Lulus Kuliah",
    "Kurang Pengalaman",
    "Budget Pas - Pasan",
    "Mager Belajar",
  ]

  const [reason, setReason] = useState(WORD_LIST[0])
  const [index, setIndex] = useState(0)
  const [fade, setFade] = useState(false)
  const [fadeInterval, setFadeInterval] = useState(0)

  useInterval(() => {
    if (fadeInterval === 19) {
      setFade(true)
      setFadeInterval(fadeInterval + 1)
    } else {
      setFade(false)

      if (fadeInterval >= 20) {
        setFadeInterval(0)

        if (index === WORD_LIST.length - 1) {
          setReason(WORD_LIST[0])
          setIndex(0)
        } else {
          setReason(WORD_LIST[index + 1])
          setIndex(index + 1)
        }
      } else {
        setFadeInterval(fadeInterval + 1)
      }
    }
  }, 200)

  return (
    <section className={clsx("relative")}>
      <div>
        <Image
          src={`${HARISENIN_PUBLIC_PAGE_ASSETS}/homepage_hero-banner.webp`}
          width={1468}
          height={724}
          alt="home-banner"
          className="object-cover w-full sm:h-[44.7vw] h-[500px] bg-black"
          priority
        />
      </div>

      <FlexBox
        className={clsx(
          "font-inter absolute top-0 left-0 h-full w-full text-white",
          "sm:pb-[144px] sm:pt-[180px] py-9",
          "pt-10 pb-7",
          "bg-[rgba(0,0,0,0.85)]"
        )}
        justify="center"
        align="center"
        direction="col"
      >
        <div className="text-sm mb-2">*Updated: {info?.last_updated_local_date}</div>

        <h1
          id="hp-banner"
          className={clsx(
            "font-urbanist sm:text-[64px] text-white font-bold text-center sm:leading-[70%]",
            "text-4xl",
            "sm:mt-3 mt-2 sm:mb-6 mb-4"
          )}
        >
          Dapat Kerja <br className="sm:hidden" /> Lebih Cepat <hr className="sm-only:my-0" />{" "}
          <span className="text-new-green">Walau</span> <br className="sm:hidden" />
          <Transition
            as={Fragment}
            appear
            show={!fade}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <span className="text-new-green">{reason}.</span>
          </Transition>
        </h1>

        <p className={clsx("font-inter sm:w-1/2 w-3/4  sm:mb-7.5 mb-5", "text-center sm-only:text-xs")}>
          <b>Mission-Based Learning</b> harisenin.com didesain agar kamu gak males belajar & bisa
          lebih cepat dapat kerja. Dalam 2,5 Tahun harisenin.com telah membantu {info?.total_alumni}
          * alumni meraih karir impiannya lebih cepat.
        </p>

        <LinkButton
          variant="new-blue"
          customPadding="sm:py-4 sm:px-10 px-4 py-3.5"
          className="!rounded-10 sm-only:w-4/5 !font-inter"
          href="https://api.whatsapp.com/send?phone=6281312117711"
        >
          Konsultasikan masa depanmu. Gratis!
        </LinkButton>

        <FlexBox className={clsx("mt-[30px] sm:gap-3", "sm-only:justify-center")} align="center">
          <div className="sm-only:hidden relative sm:h-[42px] sm:w-20">
            {info?.user_picture.map((picture, index) => (
              <div
                key={index}
                className={clsx(
                  "rounded-full border-2 border-new-blue absolute top-0 right-0",
                  index === 1 && "sm:right-[20px] right-[4px]",
                  index === 2 && "sm:right-[40px] right-[10px]",
                  index === 3 && "sm:right-[60px] right-[16px]",
                  index === 4 && "sm:right-[80px] right-[16px]",
                  "sm:w-[42px] sm:h-[42px] wh-7"
                )}
              >
                <Image
                  src={picture}
                  width={42}
                  height={42}
                  alt="User Picture"
                  className={clsx(IMAGE_COVER, "sm:wh-full")}
                />
              </div>
            ))}
          </div>

          <FlexBox className="gap-[2px] text-sm" align="center">
            <span>⭐ </span>{" "}
            <span className="font-bold sm-only:text-xs">{info?.average_rating}/5</span>{" "}
            <span>
              <BsDot />
            </span>{" "}
            <span className="sm-only:text-xs font-inter">Review dari {info?.total_alumni}+ Alumni</span>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </section>
  )
}
